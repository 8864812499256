@import 'react-quill/dist/quill.snow.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    background-color: #0f1320;
  }
}
.logo-shrink {
  transform: scale(0.8);
  transition: transform 0.3s ease-in-out;
}

.no-hover:hover {
  background-color: #fdbc06;
}
